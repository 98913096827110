import React, { useEffect, useCallback } from 'react'
import { flatten, path } from 'ramda'
import classNames from 'classnames'
import { GRAPH_COLORS, isSomething, getUserUnitI, getLabelForDevice, getDeviceLabel, GRAPH_TYPES, shouldGetDevice, getSuffForUser, formatDate, getMasterUnitParam, getGraphMasterParams, convertUnitForUser, getTheme } from '../../common/ambient'
import HighchartsReact from 'highcharts-react-official'
import bindAllActions from '../../common/bindAllActions'
import AdvancedGraphEdit from './AdvancedGraphEdit'
import DebouncedInput from '../common/DebouncedInput'
import AwnSwitch from '../common/AwnSwitch'
import { DatePicker } from '../../components'
import { useDeviceData, generateDeviceDataDataKey, getDayKeys } from './redux/deviceData'
import SwitchSlider from '../common/SwitchSlider'
// import PropTypes from 'prop-types';

/**
 * Questions:
 * MVP:
 * Sooner Add-on:
 * * adjust colors
 * * custom previos period
 * * adjust axis
 * * adjust resolution
 * * grpah based time period
 * Features:
 * * cacheing data in the browser
 */

// Function to determine the number of decimal places
const getDecimalPlaces = (num) => {
  if (!num) return 0;
  if (Math.floor(num) === num) return 0;
  return num.toString().split(".")[1].length || 0;
};

// Function to round to a specific number of decimal places
const roundToDecimalPlaces = (num, places) => {
  return Number(Math.round(num + "e" + places) + "e-" + places);
};

function scrollToMiddle(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    const elementTop = element.offsetTop;
    const elementHeight = element.offsetHeight;
    const windowHeight = window.innerHeight;
    const scrollToPosition = elementTop - (windowHeight / 2) + (elementHeight / 2);

    window.scrollTo({
      top: scrollToPosition,
      behavior: 'smooth'
    });
  }
}

function lightenColor(hexColor, percentage) {
  // Remove the # if present
  hexColor = hexColor.replace(/^#/, '');

  // Parse the hex color to RGB
  let r = parseInt(hexColor.slice(0, 2), 16);
  let g = parseInt(hexColor.slice(2, 4), 16);
  let b = parseInt(hexColor.slice(4, 6), 16);

  // Calculate the amount to lighten
  const amount = Math.floor((255 - r) * (percentage / 100));

  // Lighten each component
  r = Math.min(r + amount, 255);
  g = Math.min(g + amount, 255);
  b = Math.min(b + amount, 255);

  // Convert back to hex
  const newHex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

  return `#${newHex}`;
}

const graphTypes = GRAPH_TYPES

const graphReset = () => ({
  series: []
})
const TIME_PERIODS = {
  year: 'Previous Year',
  period: 'Previous Period'
}
function AdvancedGraph ({ device, deviceActions, dates, user, userActions, i }) {
  const graphs = path(['info', 'settings', 'graphs'], user) || []
  const { deviceCache, deviceDataCache } = device
  const { getDeviceData, isDeviceDataComplete, deviceData } = useDeviceData()
  const [chartOptions, setChartOptions] = React.useState(graphReset())
  const [editingGraph, setEditingGraph] = React.useState(false)
  const [dataKeys, setDataKeys] = React.useState([])
  const [lastFetch, setLastFetch] = React.useState(Date.now())
  const graph = graphs[i]
  const getSuff = useCallback(getSuffForUser(user), [user])


  // keep data keys in sync
  useEffect(() => {
    if (!graph) return
    const devices = graph.devices.map(({ deviceId }) => deviceCache[deviceId]).filter(Boolean)
    if (devices.length !== graph.devices.length) return
    
    // Determine which dates to use - custom or global
    const useDates = graph.customDateRange && graph.customDateRange.start && graph.customDateRange.end
      ? [moment(graph.customDateRange.start), moment(graph.customDateRange.end)]
      : dates
      
    if (!useDates[0] || !useDates[1]) return
    
    let dKeys = devices.map(theDevice => {
      const key = generateDeviceDataDataKey(theDevice.macAddress, useDates[0].valueOf(), useDates[1].valueOf())
      return key
    })
    
    if (graph.time && graph.time.period) {
      if (graph.time.period === 'year') {
        dKeys = dKeys.concat(devices.map(theDevice => {
          const key = generateDeviceDataDataKey(theDevice.macAddress, useDates[0].clone().subtract(1, 'year').valueOf(), useDates[1].clone().subtract(1, 'year').valueOf())
          return key
        }))
      } else if (graph.time.period === 'period') {
        const diff = useDates[1].diff(useDates[0], 'days')
        dKeys = dKeys.concat(devices.map(theDevice => {
          const key = generateDeviceDataDataKey(theDevice.macAddress, useDates[0].clone().subtract(diff, 'days').valueOf(), useDates[1].clone().subtract(diff, 'days').valueOf())
          return key
        }))
      }
    }
    
    // if the data keys are the same, don't reset
    if (dKeys.join() === dataKeys.join()) return
    setDataKeys(dKeys)
    dKeys.forEach(deviceData) // fetch the data
  }, [graph, dates, deviceCache, deviceData, setDataKeys, chartOptions, dataKeys])

  useEffect(() => {
    setChartOptions(graphReset())
    setLastFetch(Date.now())
  }, [dataKeys, setChartOptions, setLastFetch])

  useEffect(() => {
    if (dates.length === 0 || !graph) return
    graph.devices.forEach(({ deviceId }) => {
      if (shouldGetDevice(device, deviceId, 5)) {
        deviceActions.getDevice(deviceId)
      }
    })
    const { deviceCache } = device
    const devices = graph.devices.map(({ deviceId }) => deviceCache[deviceId]).filter(Boolean)
    if (devices.length !== graph.devices.length) return
    if (dataKeys.length === 0) return
    if (dataKeys.every(isDeviceDataComplete) && chartOptions.series.length === 0) {
      let yAxis = {
        title: {
          text: undefined
        }
      }
      let series = []
      
      // Determine which dates to use - custom or global
      const useDates = graph.customDateRange && graph.customDateRange.start && graph.customDateRange.end
        ? [moment(graph.customDateRange.start), moment(graph.customDateRange.end)]
        : dates
        
      const processDate = (dateutc, dontInvert) => {
        const fn = dontInvert ? 'subtract' : 'add'
        if (graph.time.period === 'year') {
          return moment(dateutc)[fn](1, 'year').valueOf()
        }
        return moment(dateutc)[fn](useDates[1].diff(useDates[0], 'days'), 'days').valueOf()
      }
      const convertUnit = (doc, param) => convertUnitForUser(user, param, doc[param])
      if (graph.type === 'multiDevice') {
        yAxis = {
          ...yAxis,
          labels: {
            format: '{value}' + getSuff(graph.params[0].param)
          }
        }
        series = dataKeys.map((dk, i) => {
          let theDevice = devices[i]
          let name = getDeviceLabel(theDevice)
          let data = getDeviceData(dk).map(d => [d.dateutc, convertUnit(d, graph.params[0].param)])
          if (graph.deltas && graph.deltas.device) {
            const baseLineDevice = device.deviceCache[graph.deltas.device];
            const baseData = getDeviceData(generateDeviceDataDataKey(baseLineDevice.macAddress, dates[0].valueOf(), dates[1].valueOf()))
              .map(d => [d.dateutc, convertUnit(d, graph.params[0].param)]);
            data = data
              .filter(d => baseData.find(bd => bd[0] >= d[0] && isSomething(d[1])))
              .map((d) => {
                const baseValue = baseData.find(bd => bd[0] >= d[0])[1];
                const originalValue = d[1];
                const difference = originalValue - baseValue;
                // Determine the maximum decimal places from both numbers
                const maxDecimalPlaces = Math.max(
                  getDecimalPlaces(originalValue),
                  getDecimalPlaces(baseValue)
                );
                // Round the difference to the same number of decimal places
                const roundedDifference = roundToDecimalPlaces(difference, maxDecimalPlaces);
                return [d[0], roundedDifference];
              });
          }
          if (graph.time && graph.time.period) {
            const mySibling = dataKeys.findIndex((k, j) => k !== dk && k.split('-')[0] === dk.split('-')[0])
            // previous period dk
            if (mySibling < i) {
              data = data.map((d) => [processDate(d[0]), d[1]])
              name = TIME_PERIODS[graph.time.period] + `: ${moment(processDate(useDates[0].valueOf(), true)).format('M/D/YY')} - ${moment(processDate(useDates[1].valueOf(), true)).format('M/D/YY')}`
              theDevice = devices[mySibling]
            }
          }
          return {
            dataKey: dk,
            name,
            data,
            type: (theDevice && graph.devices.find(d => theDevice._id === d.deviceId).type) || 'line',
            param: graph.params[0].param
          }
        })
          .sort((a, b) => b.dataKey.localeCompare(a.dataKey))
        if (graph.time && graph.time.period) {
          series = series.map((s, i) => {
            if (i > 0 && s.dataKey.split('-')[0] === series[i - 1].dataKey.split('-')[0]) {
              s.color = lightenColor(GRAPH_COLORS[Math.floor(i / 2)], 40)
            }
            return s
          })
        }
      // multiparam
      } else {
        const masterParams = getGraphMasterParams(graph)
        series = graph.params.map(({ param, type }) => {
          const name = getLabelForDevice(param, device.deviceCache[graph.devices[0].deviceId])
          let data = getDeviceData(dataKeys[0]).map(d => [d.dateutc, convertUnit(d, param)])
          if (graph.deltas && graph.deltas.param) {
            const baseData = getDeviceData(dataKeys[0]).map(d => [d.dateutc, convertUnit(d, graph.deltas.param)]);
            data = data.map((d, i) => {
              const originalValue = d[1];
              const baseValue = baseData[i][1];
              const difference = originalValue - baseValue;
              
              // Determine the maximum decimal places from both numbers
              const maxDecimalPlaces = Math.max(
                  getDecimalPlaces(originalValue),
                  getDecimalPlaces(baseValue)
              );
              
              // Round the difference to the same number of decimal places
              const roundedDifference = roundToDecimalPlaces(difference, maxDecimalPlaces);
              
              return [d[0], roundedDifference];
            });
          }
          return {
            type: type || 'line',
            param,
            name,
            data,
            yAxis: masterParams.indexOf(getMasterUnitParam(param) || param)
          }
        })
        if (graph.time && graph.time.period) {
          graph.params.forEach(({ param }, j) => {
            if (dataKeys[1]) {
              const name = getLabelForDevice(param, device.deviceCache[graph.devices[0].deviceId])
              const data = getDeviceData(dataKeys[1]).map(d => [processDate(d.dateutc), convertUnit(d, param)])
              const seriesIndex = series.findIndex(s => s.name === name)
              series = [...series.slice(0, seriesIndex + 1), {
                param,
                color: lightenColor(GRAPH_COLORS[j], 20),
                name: TIME_PERIODS[graph.time.period] + `: ${moment(processDate(useDates[0].valueOf(), true)).format('M/D/YY')} - ${moment(processDate(useDates[1].valueOf(), true)).format('M/D/YY')}`,
                data
              }, ...series.slice(seriesIndex + 1)]
            }
          })
        }
        if (masterParams.length > 1) {
          yAxis = masterParams.map((param, i) => ({
            labels: {
              format: '{value}' + getSuff(param)
            },
            title: {
              text: getLabelForDevice(param, device.deviceCache[graph.devices[0].deviceId])
            },
            opposite: i % 2 === 1
          }))
        } else {
          yAxis.labels = {
            format: '{value}' + getSuff(masterParams[0])
          }
        }
      }
      const co = {
        chart: {
          backgroundColor: '#fff',
          zooming: {
            type: 'x'
          },
          style: {
            fontSize: '16px'
          }
        },
        time: {
          useUTC: false,
          timezone: 'local'
        },
        exporting: {
          buttons: {
            contextButton: {
              // x: 0,
              y: -10,
              useHTML: true,
              symbol: null,
              width: 10,
              text: '<i class="csv" />'
            }
          }
        },
        colors: GRAPH_COLORS,
        title: {
          text: graphTypes[graph.type].name
        },
        tooltip: {
          shared: true,
          // positioner: function () {
          //   return { x: 80, y: 0 };
          // },
          shadow: false,
          borderWidth: 0,
          formatter: function () {
            let s = '<b>' + formatDate(false, this.x) + '</b><br/><br/>'
            this.points.forEach(function (point) {
              let symbol = ''
              // Create the marker symbol
              switch (point.series.symbol) {
                case 'circle':
                  symbol = '●'
                  break;
                case 'diamond':
                  symbol = '♦'
                  break;
                case 'square':
                  symbol = '■'
                  break;
                case 'triangle':
                  symbol = '▲'
                  break;
                case 'triangle-down':
                  symbol = '▼'
                  break;
                default:
                  symbol = '●'
              }
              s += '<span style="display:inline-block;width:30px;color:' + point.series.color + '">' + symbol + '</span> ' + point.series.name + ': <b>' + point.y + `${getSuff(point.series.userOptions.param)}</b><br/>`
            })
            return s
          }
        },
        yAxis,
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            hour: getUserUnitI('hour24', user) === 1 ? '%H:%M' : '%l:%M%P'
          },
        },
        series
      }
      // dark mode
      if (getTheme(user) === 'dark' && co.xAxis) {
        const labels = {
          style: {
            color: 'white'
          }
        }
        co.chart.backgroundColor = '#2c2c2e'
        co.exporting.buttons.contextButton.theme = {
          fill: '#000',
          stroke: 'none',
          padding: 5,
          'stroke-linecap': 'round'
        }
        co.xAxis.labels = labels
        co.xAxis.lineColor = '#e6e6e6'
        if (Array.isArray(co.yAxis)) {
          co.yAxis.forEach(y => {
            y.labels = {
              ...y.labels,
              ...labels
            }
            y.title = {
              ...y.title,
              ...labels
            }
            y.gridLineColor = '#333'
          })
        } else {
          co.yAxis.labels = {
            ...co.yAxis.labels,
            ...labels
          }
          co.yAxis.title = {
            ...co.yAxis.title,
            ...labels
          }
          co.yAxis.gridLineColor = '#333'
        }
        co.legend = {
          itemStyle: {
            color: 'white'
          }
        }
      }
      console.log('setChartOptions', co)
      return setChartOptions(co)
    }
  }, [graph, dates, device, deviceActions, setChartOptions, chartOptions, isDeviceDataComplete, getDeviceData, dataKeys, getSuff, user])
  // reset graphs when dates change (global or custom)
  useEffect(() => {
    setChartOptions(graphReset())
  }, [dates, graph, setChartOptions])
  if (!graph) return null

  const isMultiDevice = graph.type === 'multiDevice'
  const isMultiParam = graph.type === 'multiParam'
  const chartReset = () => {
    setChartOptions({
      ...chartOptions,
      series: []
    })
  }
  const updateGraphs = (graphs) => {
    // console.log('updateGraphs', graphs)
    userActions.updateSetting('graphs', graphs)
    chartReset()
  }
  const isComparing = graph.deltas || graph.time
  const hasMoreThanOneUnit = getGraphMasterParams(graph).length > 1
  let show = (
    <>
      <div className={classNames('compare', { gtOne: hasMoreThanOneUnit })}>
        <div className='switch'>
          <span>
            Compare
          </span>
          <AwnSwitch
            checked={isComparing}
            onChange={checked => {
              updateGraphs(graphs.map((g, j) => {
                if (i !== j) return g
                if (checked) {
                  if (hasMoreThanOneUnit) {
                    g.time = {}
                  } else {
                    g.deltas = {}
                  }
                } else {
                  delete g.deltas
                  delete g.time
                }
                return g
              }))
            }}
          />
        </div>
        {isComparing && (
          <SwitchSlider
            onChange={value => {
              updateGraphs(graphs.map((g, j) => {
                if (i !== j) return g
                if (value === 'deltas') {
                  delete g.time
                  g.deltas = {}
                } else {
                  delete g.deltas
                  g.time = {}
                }
                return g
              }))
            }}
            value={graph.deltas ? 'deltas' : 'time'}
            options={[{
              label: 'Deltas',
              value: 'deltas'
            }, {
              label: 'Previous Period',
              value: 'time'
            }]}
          />
        )}
        {graph.deltas && (
          hasMoreThanOneUnit
            ? <p className='error'>Your parameters must have the same units to use the deltas feature.</p>
            : (
              <>
                {isMultiDevice && (
                  <select
                    className='form-control'
                    value={graph.deltas.device || ''}
                    onChange={evt => {
                      updateGraphs(graphs.map((g, j) => {
                        if (i !== j) return g
                        g.deltas.device = evt.target.value
                        return g
                      }))
                    }}
                  >
                    <option value=''>{graph.deltas.device ? 'No Baseline' : 'Choose Baseline Station:'}</option>
                    {graph.devices.map(p => (
                      <option key={p} value={p.deviceId}>Baseline: {getDeviceLabel(deviceCache[p.deviceId])}</option>
                    ))}
                  </select>
                )}
                {isMultiParam && (
                  <select
                    className='form-control'
                    value={graph.deltas.param || ''}
                    onChange={evt => {
                      updateGraphs(graphs.map((g, j) => {
                        if (i !== j) return g
                        g.deltas.param = evt.target.value
                        return g
                      }))
                    }}
                  >
                    <option value=''>{graph.deltas.param ? 'No Baseline' : 'Choose Baseline Parameter:'}</option>
                    {graph.params.map(({ param }) => (
                      <option key={param} value={param}>Baseline: {getLabelForDevice(param, device.deviceCache[graph.devices[0].deviceId])}</option>
                    ))}
                  </select>
                )}
              </>
              )
        )}
        {graph.time && (
          <select
            className='form-control'
            value={graph.time.period || ''}
            onChange={evt => {
              updateGraphs(graphs.map((g, j) => {
                if (i !== j) return g
                g.time.period = evt.target.value
                return g
              }))
            }}
          >
            <option value=''>{graph.time.period ? 'No Comparison' : 'Choose Time Period:'}</option>
            {Object.keys(TIME_PERIODS).map(p => (
              <option key={p} value={p}>{TIME_PERIODS[p]}</option>
            ))}
          </select>
        )}
      </div>
      <HighchartsReact
        highcharts={window.Highcharts}
        options={chartOptions}
      />
    </>
  )
  if (editingGraph) {
    show = (
      <div className='editing-graph'>
        <AdvancedGraphEdit
          graph={editingGraph}
          setGraph={setEditingGraph}
          device={device}
          advanced
        />
        <div className='text-right'>
          <a className='btn btn-cancel' onClick={() => setEditingGraph(false)}>Cancel</a>
          <a
            className='btn btn-primary' onClick={() => {
              updateGraphs(graphs.map((g, j) => {
                if (i !== j) return g
                return editingGraph
              }))
              setEditingGraph(false)
            }}
          >Finish Editing
          </a>
        </div>
      </div>
    )
  }
  if (dataKeys.find(k => !isDeviceDataComplete(k))) {
    const allDayKeys = flatten(dataKeys.map(getDayKeys))
    const dayKeysRemaining = allDayKeys.filter(k => !deviceDataCache[k])
    const percent = Math.round((allDayKeys.length - dayKeysRemaining.length) / allDayKeys.length * 100)
    const timeRemaining = Math.round((Date.now() - lastFetch) / percent * (100 - percent)) * 1.2
    const readable = timeRemaining > 60000 ? Math.round(moment.duration(timeRemaining).asMinutes()) + 'm' : Math.round(moment.duration(timeRemaining).asSeconds()) + 's'
    show = (
      <div className='loading-wrap'>
        <div className='progress-wrap'>
          <div className='progress'>
            <div className='blue progress-bar' style={{ width: (percent) + '%' }} />
          </div>
        </div>
        <p>
          {timeRemaining > 0 && timeRemaining !== Infinity ? `Estimated time remaining: ${readable}` : 'Loading...'}
        </p>
      </div>
    )
  }
  if (graph.minimized) {
    show = null
  }
  return (
    <div
      id={`graph-${graph._id}`}
      className={classNames('device-advanced-graph block graph-wrap', {
        edit: editingGraph,
        minimized: graph.minimized
      })}
    >
      <h3>
        <DebouncedInput
          initialValue={graph.title}
          onChange={title => {
            if (editingGraph) {
              setEditingGraph(Object.assign({}, editingGraph, { title }))
            } else {
              updateGraphs(graphs.map((g, j) => {
                if (i !== j) return g
                g.title = title
                return g
              }))
            }
          }}
        />
      </h3>
      <div className={classNames('graph-header', { enabled: graph.customDateRange })}>
        <i className="glyphicon glyphicon-calendar" title="Use custom date range" />
        <div className='switch'>
          <AwnSwitch
            checked={!!graph.customDateRange}
            onChange={checked => {
              updateGraphs(graphs.map((g, j) => {
                if (i !== j) return g
                if (checked) {
                  // Initialize customDateRange with default dates
                  const now = moment()
                  g.customDateRange = {
                    start: now.clone().subtract(24, 'hours'),
                    end: now.clone()
                  }
                } else {
                  delete g.customDateRange
                }
                return g
              }))
              // Reset chart when custom date toggle changes
              chartReset()
            }}
          />
        </div>
        {graph.customDateRange && (
          <DatePicker
            onChange={newDates => {
              if (newDates && newDates.length >= 2) {
                updateGraphs(graphs.map((g, j) => {
                  if (i !== j) return g
                  g.customDateRange = {
                    start: newDates[0],
                    end: newDates[1]
                  }
                  return g
                }))
                // Reset chart when dates change
                chartReset()
              }
            }}
            start={graph.customDateRange.start ? moment(graph.customDateRange.start) : undefined}
            end={graph.customDateRange.end ? moment(graph.customDateRange.end) : undefined}
          />
        )}
      </div>
      {show}
      <div className='buttons-wrap'>
        <div className='buttons-rounded'>
          <a
            onClick={() => {
              setEditingGraph(Object.assign({}, graph))
            }}
            className='trash glyphicon glyphicon-pencil'
            title='Edit Graph'
          />
          <a
            onClick={() => {
              updateGraphs([...graphs.slice(0, i + 1), Object.assign({}, graph, { _id: Math.random() }), ...graphs.slice(i + 1)])
            }}
            className='trash glyphicon glyphicon-duplicate'
            title='Duplicate Graph'
          />
          <a
            onClick={() => {
              updateGraphs(graphs.map((g, j) => {
                if (i !== j) return g
                g.minimized = !g.minimized
                return g
              }))
            }}
            className={graph.minimized ? 'exp' : 'minus'}
          />
          {i > 0 && (
            <a
              onClick={() => {
                updateGraphs([...graphs.slice(0, i - 1), graph, graphs[i - 1], ...graphs.slice(i + 1)]);
                scrollToMiddle(`graph-${graphs[i - 1]._id}`)
              }}
              className='trash glyphicon glyphicon-chevron-up'
            />
          )}
          {i < graphs.length - 1 && (
            <a
              onClick={() => {
                updateGraphs([...graphs.slice(0, i), graphs[i + 1], graph, ...graphs.slice(i + 2)]);
                scrollToMiddle(`graph-${graphs[i + 1]._id}`)
              }}
              className='trash glyphicon glyphicon-chevron-down'
            />
          )}
          <a
            onClick={() => {
              if (window.confirm('Are you sure you want to delete this graph?')) {
                updateGraphs(graphs.filter((g, j) => i !== j))
              }
            }}
            className='trash glyphicon glyphicon-trash'
            title='Delete Graph'
          />
        </div>
      </div>
    </div>
  )
}
export default bindAllActions(AdvancedGraph)

AdvancedGraph.propTypes = {}
AdvancedGraph.defaultProps = {}

AdvancedGraph.displayName = 'AdvancedGraph'
